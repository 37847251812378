<template>
    <p class="clearfix mb-0">
        <span class="float-md-left d-block d-md-inline-block mt-25">
            COPYRIGHT © 2022 {{ new Date().getFullYear() == 2022 ? '' : ` - ${new Date().getFullYear()}` }}
            <b-link class="ml-25" :href="hrefWeb" target="_blank"><strong>BOTANICANN</strong></b-link>
            <span class="d-none d-sm-inline-block">, TODOS LOS DERECHOS RESERVADOS.</span>
        </span>
    </p>
</template>

<script>

    import { BLink } from 'bootstrap-vue';

    export default {
        components: {
            BLink
        },
        data (){
            return {
                hrefWeb: process.env.VUE_APP_WEB_URL
            }
        }
    }

</script>
