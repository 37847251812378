export default [{
        title: 'Inicio',
        route: 'home',
        icon: 'HomeIcon'
    },
    {
        title: 'Productos',
        icon: 'ShoppingBagIcon',
        children: [{
            title: 'Lista',
            route: 'products'
        }, {
            title: 'Comentaris',
            route: 'product-comments'
        }]
    },
    {
        title: 'Cupones de descuento',
        route: 'discountcoupons',
        icon: 'PaperclipIcon'
    },
    {
        title: 'Formulario de Contáctanos',
        route: 'messages',
        icon: 'MessageSquareIcon'
    },
    {
        title: 'Ventas',
        route: 'sales',
        icon: 'ShoppingCartIcon'
    },
    {
        title: 'Suscriptores',
        route: 'subscribers',
        icon: 'UsersIcon'
    },
    {
        title: 'Blogs',
        route: 'blogs',
        icon: 'TrelloIcon'
    }
]