<template>
    <div class="navbar-container d-flex content align-items-center">

        <!-- Nav Menu Toggler -->
        <ul class="nav navbar-nav d-xl-none">
            <li class="nav-item">
                <b-link
                    class="nav-link"
                    @click="toggleVerticalMenuActive"
                >
                    <feather-icon
                        icon="MenuIcon"
                        size="21"
                    />
                </b-link>
            </li>
        </ul>
    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
        <!-- <dark-Toggler class="d-none d-lg-block"></dark-Toggler> -->
    </div>

        <b-navbar-nav class="nav align-items-center ml-auto">
            <b-nav-item-dropdown
                right
                toggle-class="d-flex align-items-center dropdown-user-link"
                class="dropdown-user"
            >
                <template #button-content>
                    <div class="d-sm-flex d-none user-nav">
                        <p class="user-name font-weight-bolder mb-0">
                            {{ userData.names }} {{ userData.surnames}}
                        </p>
                        <span class="user-status">{{ userData.roleAlias }}</span>
                    </div>
                    <b-avatar
                        size="40"
                        variant="light-primary"
                        :src="userData.photo ? require(`@/assets/images/modules/profiles/${userData.photo}`) : null"
                    />
                </template>

                <!-- 
                <b-dropdown-item
                    :to="{ name: 'account-setting' }"
                    link-class="d-flex align-items-center">
                    <feather-icon
                        size="16"
                        icon="UserIcon"
                        class="mr-50"
                    />
                    <span>Perfil</span>
                </b-dropdown-item>
                -->

                <!-- <b-dropdown-divider /> -->

                <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
                    <feather-icon
                        size="16"
                        icon="LogOutIcon"
                        class="mr-50"
                    />
                    <span>Cerrar sesión</span>
                </b-dropdown-item>
            </b-nav-item-dropdown>
        </b-navbar-nav>
    </div>
</template>

<script>

    import { BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, } from 'bootstrap-vue';
    import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue';
    import { initialAbility } from '@/libs/acl/config';
    import useJwt from '@/auth/jwt/useJwt';
    import { avatarText } from '@core/utils/filter';

    export default {
        components: {
            BLink,
            BNavbarNav,
            BNavItemDropdown,
            BDropdownItem,
            BDropdownDivider,
            BAvatar,

            // Navbar Components
            DarkToggler,
        },
        data() {
            return {
                userData: JSON.parse(localStorage.getItem('userData')),
                avatarText,
            }
        },
        props: {
            toggleVerticalMenuActive: {
                type: Function,
                default: () => {},
            },
        },
        methods: {
            logout() {
                // Remove userData from localStorage
                // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
                localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
                localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

                // Remove userData from localStorage
                localStorage.removeItem('userData');

                // Reset ability
                this.$ability.update(initialAbility);

                // Redirect to login page
                this.$router.push({ name: 'login' });
            },
        }
    }

</script>

<style scoped>

    .user-status {
        text-transform: capitalize;
    }

</style>
